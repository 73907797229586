import { useState } from 'react';

import { Card, Form } from 'react-bootstrap';

type SerifConfigurationProps = {
    speed: number
};

function SerifConfiguration(props: SerifConfigurationProps) {
    const [ speed, setSpeed ] = useState(props.speed);
    return <Card>
          <Card.Body>
          <Card.Title>セリフごとの設定</Card.Title>
          <Form>
          <Form.Group>
          <Form.Label>キャラクター</Form.Label>
          <Form.Check type="radio" label="ずんだもん" id="ずんだもん" checked />
          <Form.Check type="radio" label="めたん" id="めたん" />
          <Form.Check type="radio" label="つむぎ" id="つむぎ" />
          </Form.Group>
          <Form.Group>
          <Form.Label>背景</Form.Label>
          <Form.Check type="radio" label="教室" id="教室" checked />
          <Form.Check type="radio" label="黒背景" id="黒背景" />
          </Form.Group>
          <Form.Group>
        <Form.Label>読み上げ速度({speed * 100}%)</Form.Label>
        <Form.Range defaultValue="1.0" min="0.5" max="2" step="0.05" onChange={ e => setSpeed(Number(e.target.value)) } />
          </Form.Group>
          <Form.Group>
          <Form.Label>コード</Form.Label>
          <Form.Check type="checkbox" label="code1" id="code1" />
          <Form.Check type="checkbox" label="code2" id="code2" />
          </Form.Group>
          <Form.Group>
          <Form.Label>数式</Form.Label>
          <Form.Check type="checkbox" label="axiom1" id="axiom1" />
          <Form.Check type="checkbox" label="axiom2" id="axiom2" />
          </Form.Group>
          </Form>
          </Card.Body>
        </Card>;
}

export { SerifConfiguration, type SerifConfigurationProps };
