import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Card, Col, Container, Form, Row, Nav, Navbar } from 'react-bootstrap';
import { BadgeAd } from 'react-bootstrap-icons';
import { SerifConfiguration } from './SerifConfiguration';
import {SerifProps, Serif} from './Serif';
import logo from './zmm.png'

type Dialogue = {
    sayList: Array<SerifProps>
}

function App() {
  return (
      <Container>
          <Navbar>
          <Nav>
          <Nav.Link href="/" navbar-brand>
          <img src={logo} alt="logo" height="32px" />
          ずんだムービーメーカー(仮) (まだ動きません)
          </Nav.Link>
          </Nav>
          </Navbar>
          <Row>

          <Col xs={3}>
          <Nav className="flex-column">
          <Nav.Link href="/pref">全体設定(キャラ・音声・背景)</Nav.Link>
          <Nav.Link>やりとり編集</Nav.Link>
          <Nav.Link>Link</Nav.Link>
          <Nav.Item>
          <Card>
          <Card.Body>
          <Card.Title>
          <BadgeAd/>
          </Card.Title>
          </Card.Body>
          <Card.Body>
          adadadad
      </Card.Body>
      </Card>
          </Nav.Item>
          <Nav.Link><Button size="lg">Generate XML</Button></Nav.Link>
          <Nav.Link href="https://www.buymeacoffee.com/windymelt"><Button>💰@windymeltに寄付しよう☕</Button></Nav.Link>
    </Nav>
          </Col>

          <Col>
          <Card>
          <Card.Body>
          <Card.Title>全体設定</Card.Title>
          キャラクター
      
      背景
          </Card.Body>
          </Card>
          <Serif character="ずんだもん" serif="ずんだもんなのだ" configuration={{speed: 1}} />
          <Card>
          <Container fluid>
          <Row>
          <Col><Button size="sm">ここにセリフを追加</Button></Col>
          <Col><Button size="sm">ここにシーンを追加</Button></Col>
          </Row>
          </Container>
          </Card>
          <Serif character="めたん" serif="あらずんだもん、こんにちは" configuration={{speed: 1}} />
          </Col>

          <Col xs={3}>
          <SerifConfiguration speed={1} />
          </Col>

          </Row>
          </Container>
  );
}

export default App;
