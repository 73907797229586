import { useState } from 'react';

import { Card, Container, Form, Row } from 'react-bootstrap';
import { SerifConfigurationProps } from './SerifConfiguration';

type SerifProps = {
    character: string,
    serif: string,
    configuration: SerifConfigurationProps,
};

function Serif(props: SerifProps) {
    const [ serif, setSerif ] = useState(props.serif)

    return <Card>
          <Card.Body>
        <Card.Title>{props.character}</Card.Title>
          <Form>
        <Form.Control as="textarea" rows={2} value={serif} onChange={ e => setSerif(e.target.value) } />
          </Form>
          </Card.Body>
        </Card>;
}

export { type SerifProps, Serif };
